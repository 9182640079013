<template>
  <div class="navsed">
    <search />
    <div id="navsed">
      <!-- <el-menu
        class="el-menu-demo"
        :default-active="this.$route.path"
        mode="horizontal"
        :router="true"
      >
        <el-menu-item index="/index">首页</el-menu-item>
        <el-menu-item index="/newsFlash">快讯</el-menu-item>
        <el-menu-item index="/Recommend">推荐</el-menu-item>
        <el-menu-item index="/Hotlist">热榜</el-menu-item>
        <el-menu-item index="/Theme">主题</el-menu-item>
      </el-menu> -->
      <router-view />
    </div>
  </div>
</template>

<script>
import search from "@/components/search";
export default {
  data() {
    return {
      active: 1,
      mouseoverFlag: false // 热榜未点击前，mouserover无效
    };
  },
  mounted() {
    this.$root.Hub.$on("changeState2", x => {
      console.log("x", x);
      this.mouseoverFlag = x;
    });
  },
  created() {
    this.active = this.active;
  },
  components: {
    search
  },
  methods: {
    selectItem(index) {
      this.active = index;
    },
    translateState(index) {
      this.active = index;
      console.log("热榜 被点击了");
      let stateof = true;
      this.$root.Hub.$emit("changeState", stateof);
      this.mouseoverFlag = true;
    },
    translateState2() {
      console.log("mouseover");
      let stateof = true;
      this.$root.Hub.$emit("changeState", stateof);
    }
  }
};
</script>

<style scoped>
#navsed {
  /* width: 100%; */
  /* border: 1px solid forestgreen; */
  background-color: #f7f7f7;
}
.head_navsed {
  width: 100%;
  overflow: hidden;
  text-align: center;
  height: 90px;

  /* border: 1px solid red; */
}
a {
  text-decoration: none;
  margin-right: 60px;
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(74, 73, 73, 1);
  line-height: 50px;
  height: 50px;
  /* font-weight: bold; */
  color: #2c3e50;
}
.active {
  /* border-bottom: 2px solid rgba(0,132,255,1); */
  color: #42b983;
}
/* .el-menu-demo {
  background-color: #f7f7f7;
  border-bottom: none;
  margin-left: 29%;
  height: 90px;
  line-height: 90px;
} */
.el-menu-item {
  border-bottom: none;
  text-decoration: none;
  margin-right: 30px;
  font-size: 21px;
  margin-top: 15px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(74, 73, 73, 1);
  line-height: 50px;
  height: 50px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  color: #42b983;
  border: none;
  background: #f7f7f7;
}
</style>
