<template>
  <!-- 问答首页 -->
  <div class="containers">
    <!-- 搜索栏 -->
    <div class="search-bar">
      <img class="qa-icon" src="../../assets/icon/blue_hat_xjj.png">
      <!-- <span class="block qa-icon"></span> -->
      <span class="block label" @click="index">知识头条</span>

      <el-menu
        class="el-menu-demo"
        :default-active="this.$route.path"
        mode="horizontal"
        :router="true"
      >
        <el-menu-item index="/index">首页</el-menu-item>
        <el-menu-item index="/newsFlash">快讯</el-menu-item>
        <el-menu-item index="/Recommend">推荐</el-menu-item>
        <el-menu-item index="/Hotlist">热榜</el-menu-item>
        <el-menu-item index="/Theme">主题</el-menu-item>
      </el-menu>

      <div class="block input">
        <input
          type="text"
          style="font-size:15px"
          v-model="input2"
          @keyup.enter="Search"
          placeholder="请输入内容"
        />
        <span class="search-icon" @click="Search"></span>
      </div>
      <a href="javaScript:void(0)" @click="Release" class="block">发布</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "search",
  props: {
    msg: String,
  },
  data() {
    return {
      input2: "",
      info: true,
    };
  },
  methods: {
    index() {
      this.$router.push({ name: "home" });
    },
    Search() {
      this.$router.push({
        name: "search",
        params: { name: this.input2, userId: 123 },
      });
    },
    Release() {
      this.$router.push({ name: "Release", params: { userId: 123 } });
    },
  },
};
</script>

<style scoped>
.nav {
  padding: 0;
  margin: 0;
  border: 0;
}
.search-bar {
  /* text-align: center; */
  padding: 20px 0;
  border-bottom: 2px solid rgba(206, 213, 215, 1);
  background: rgba(255, 255, 255, 1);
  /* height: 45px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-bar .block {
  font-size: 12px;
  display: inline-block;
}
.search-bar .qa-icon {
  width: 43px;
  margin-bottom: 10px;
  /* height: 38px; */
  /* vertical-align: middle; */
  /* background-image: url("../../assets/icon/blue_hat_xjj.png"); */
  /* background-repeat: no-repeat; */
}
.search-bar .label {
  margin-left: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(0, 132, 255, 1);
  font-weight: bold;
}
.search-bar .input {
  /* width: 316px;
  height: 34px; */
  width: 210px;
  height: 34px;
  background: rgba(238, 238, 238, 1);
  border: 1px solid rgba(181, 193, 197, 1);
  border-radius: 17px;
  text-align: left;
  vertical-align: middle;
  /* margin-left: 61px; */
  /* border: 1px solid red; */
}
.search-bar .input input {
  height: 32px;
  border: none;
  width: 150px;
  background: rgba(238, 238, 238, 1);
  margin-left: 17px;
  vertical-align: middle;
  outline: none;
}
.search-bar .input .search-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/fdj2.png");
  background-size: 24px 24px;
  /* background-position-x: 410px;
    background-position-y: -40px; */
  vertical-align: middle;
}
.search-bar a {
  width: 78px;
  height: 28px;
  background: rgba(0, 132, 255, 1);
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  line-height: 28px;
  vertical-align: middle;
  text-align: center;
  margin-left: 26px;
}

.containers {
  background-color: #f7f7f7;
}
.el-menu-demo {
  border-bottom: none;
  margin:0 40px;
}
.el-menu-demo >>> .el-menu-item{
  font-size: 18px;
  color:#3D3D3D;
}
</style>
